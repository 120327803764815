import '../../App.scss';
import logo from '../../Assets/Images/logo.svg';
import amico from '../../Assets/Images/amico.svg';


function ProductCreated () {
  return (
    <div className="App bga">
      <header className="App-header wel">
          <a className='logo-hl' href="https://brandinit.com" target="_blank">
            <img src={logo} className='App-logo' />
          </a>
        <div></div>
      </header>
      <main className='App-body container main-body cn full'>
        <img src={amico} alt='' style={{marginBottom: "1em"}} />
        <h1 className='mainh cn'>Congratulations!<br />You have created your product</h1>
        <span style={{margin: "1em", textAlign: "center"}} className='nobr'>Your product is being processed for approval, please fill out <br />your guardian details so you can check out <br />your professional dashboard</span>
        <a className='btn-next' style={{minWidth: "225px"}} href='/guardian'>Continue</a>
      </main>
    </div>
  );
}

export default ProductCreated;