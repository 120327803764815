import Container from './Container';
import Api from '../../Api.js';
import YouTube from 'react-youtube';
import React, { Component } from 'react';
import $ from "jquery";
import { withRouter } from "react-router-dom";
import mod_cancel from '../../Assets/Images/mod-cancel.png';


function ETable ({history}) {
    return (
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='col-l'>
                                <strong>Withdrawal history</strong>
                            </div>
                            <div className="card">
                                <div className="card-body for-table">
                                    <table className="table b-table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Amount</th>
                                                <th>Type</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {history.length ?
                                                history.map((his) => {
                                                    return (
                                                        <tr>
                                                            <td><span>{his.fullName}</span></td>
                                                            <td><span>£{his.amount}</span></td>
                                                            <td><span>{his.type}</span></td>
                                                            <td><span><b>{his.status}</b></span></td>
                                                            <td><span><i>{his.date}</i></span></td>
                                                        </tr>
                                                    )
                                                })
                                            :
                                                <tr>
                                                    <td colSpan={5}>
                                                        <h4 className='opac-2'><center><i>Empty</i></center></h4>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

class Earnings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            emsg: '',
            balance: 0,
            pop: false,
            pstep: 0,
            bdet: [],
            wreqs: [],
            emsg: "",
            balance1: 0,
            balance2: 0,

            y_w: 0,
            y_h: 0,

            active_bdet: null,
            accountName: "",
            bankName: "",
            sortCode: "",
            accontType: "",
            accountNumber: "",
            iban: ""
        };
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value.trim()
        });
    };

    errorHandle = (obj) => {
        let emsg = "An error occurred";
        if(obj?.response) {
            emsg = obj.response.data.message || emsg;
            this.setState({emsg, loading: false});
        } else {
            this.setState({emsg, loading: false});
        }
    }

    async componentDidMount () {
        let user = window.localStorage.getItem("seller");
            user = JSON.parse(window.localStorage.getItem("seller"));
        this.setState({user});
        this.walletBalance(user);
        this.bankDetails(user);
        this.withdrawReq(user);
        this.otherearnings(user);

        this.youtubeDim();
        $(window).resize(() => {
            this.youtubeDim();
        });
    }

    youtubeDim () {
        this.setState({
            y_w: $(".envid").innerWidth(),
            y_h: $(".envid").innerWidth() / 1.98
        });
    }

    async walletBalance (user) {
        this.setState({emsg: ""});
        await Api.get("api/Wallet/walletbalance", {"Authorization": 'Bearer '+user?.token})
        .then((resp) => {
            let data = resp.data.data;
            this.setState({balance: data});
        }).catch((obj) => {
            this.errorHandle(obj);
        });
    }

    async otherearnings (user) {
        this.setState({emsg: ""});
        await Api.get("api/Wallet/otherearnings", {"Authorization": 'Bearer '+user?.token})
        .then((resp) => {
            let data = resp.data.data;
            this.setState({balance1: data.paidIntoAccount, balance2: data.earnings});
        }).catch((obj) => {
            this.errorHandle(obj);
        });
    }

    async bankDetails (user, cb = () => {}) {
        this.setState({emsg: ""});
        await Api.get("api/Seller/bankdetails", {"Authorization": 'Bearer '+user?.token})
        .then((resp) => {
            let data = resp.data.data;
            this.setState({bdet: data});
            cb();
        }).catch((obj) => {
            this.errorHandle(obj);
        });
    }

    async withdrawReq (user) {
        this.setState({emsg: ""});
        await Api.get("api/Wallet/withdrawalrequests", {"Authorization": 'Bearer '+user?.token})
        .then((resp) => {
            let data = resp.data.data;
            this.setState({wreqs: data.items});
        }).catch((obj) => {
            this.errorHandle(obj);
        });
    }

    withdraw = async () => {
        let { user, balance, active_bdet } = this.state;
        let post_val = {userId: user?.userId, amount: balance, bankId: active_bdet.id};
        this.setState({loading: true, emsg: ""});
        await Api.post(post_val, "api/Wallet/withdraw", {"Authorization": 'Bearer '+user?.token}).then((resp) => {
            // console.log(resp.data);
            window.location.href = "";
        }).catch((obj) => {
            this.errorHandle(obj);
        });
    }

    addBdet = async () => {
        let { user, accountName, bankName, sortCode, accountNumber, accontType, iban } = this.state;
        let post_val = {userId: user?.userId, accountName, bankName, sortCode, accontType, accountNumber, iban};
        if(accountName.length && bankName.length && accountNumber.length) {
            this.setState({loading: true, emsg: ""});
            await Api.post(post_val, "api/Seller/addbankdetails", {"Authorization": 'Bearer '+user?.token}).then((resp) => {
                this.bankDetails(user, () => {
                    this.setState({loading: false, pstep: 0});
                });
            }).catch((obj) => {
                this.errorHandle(obj);
            });
        } else {
            this.setState({emsg: "Please fill all required fields", loading: false});
        }
    }

    render () {
        let { emsg, loading, pop, pstep, bdet, wreqs, balance, balance1, balance2, active_bdet, y_w, y_h } = this.state;
        return (
            <Container page="earnings">
                <div className="pagetitle">
                    <div className="navrw">
                        <button className='mob-hamburg for-mobile'>
                            <i className="bi bi-list toggle-sidebar-btn"></i>
                        </button>
                        <h1>Earnings</h1>
                    </div>
                </div>
                <div className='envid'>
                    <div>
                        {y_w > 0 ?
                        <YouTube videoId="pmO1kDQS7y4" opts={{
                            height: y_h,
                            width: y_w,
                            // playerVars: {autoplay: 1},
                        }} /> : null}
                    </div>
                </div>

                <div className='divi-d'><div></div></div>

                <div className="row">

                    <div className="col-lg-12">
                        <div className="row">

                            <div className="col-xxl-4 col-md-6">
                                <div className='col-l'>
                                    <strong>Available funds</strong>
                                </div>
                                <div className="card info-card alt">
                                    <div className="card-body">
                                        <h5 className="card-title">Profit available</h5>
                                        <div className="d-flex align-items-center">
                                            <h6 className="text-black">£{balance}</h6>
                                        </div>
                                        <button type="button" class="btn btn-success _w" onClick={() => {
                                            this.setState({pop: true, pstep: 0});
                                        }}>Withdraw Balance</button>
                                        {/* <span className='mpm' onClick={() => {
                                            this.setState({pop: true, pstep: 0});
                                        }}>Manage payout methods</span> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-4 col-md-6">
                                <div className='col-l'>
                                    <strong>Payments</strong>
                                </div>
                                <div className="card info-card alt">
                                    <div className="card-body">
                                        <h5 className="card-title">Money that is being paid to your account</h5>
                                        <div className="d-flex align-items-center">
                                            <h6 className="text-black">£{balance1}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-4 col-xl-12">
                                <div className='col-l'>
                                    <strong>Earnings</strong>
                                    <select>
                                        <option>Since Joining</option>
                                    </select>
                                </div>
                                <div className="card info-card alt">
                                    <div className="card-body">
                                        <h5 className="card-title">How much you earned since you started</h5>
                                        <div className="d-flex align-items-center">
                                            <h6 className="text-black">£{balance2}</h6>
                                        </div>
                                        <span className='span'>Your earnings since joining.</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='divi-d' style={{marginTop: "0px"}}><div></div></div>

                <ETable history={wreqs} />
                
                {pop ?
                    <div className="con-pop-up withdrw active">
                        {pstep == 0 ? <div className="con-pop ps-1">
                            <strong>Bank details</strong>
                            <p>Choose bank details</p>
                            <img src={mod_cancel} className='mod_cancel' onClick={() => {
                                this.setState({pstep: 0, pop: false});
                            }} />
                            {
                                bdet.length ? bdet.map((bd, bk) => (
                                        <button className='pay_type' onClick={() => {
                                            this.setState({active_bdet: bd, pstep: 3})
                                        }}>
                                            <i class={(bk == 0 && active_bdet == null) || (active_bdet?.id == bd.id) ? "ri-radio-button-line" : "ri-checkbox-blank-circle-line"}></i>
                                            <b>{bd.bankName} - {bd.accountNumber}</b>
                                            <ul>
                                                <li>{bd.accountName}</li>
                                            </ul>
                                        </button>
                                )) : (
                                    <label className='pay_type adnp'>
                                        <i style={{position: "unset"}}>-&nbsp;&nbsp;Add new bank details to proceed</i>
                                    </label>
                                )
                            }
                            <button className='payt_btn' onClick={() => {
                                this.setState({pstep: 1});
                            }}>+ Add new bank details</button>
                        </div> : pstep == 1 ? <div className="con-pop ps-2">
                            <strong>Add new bank</strong>
                            <p>Bank details</p>
                            <img src={mod_cancel} className='mod_cancel' onClick={() => {
                                this.setState({pstep: 0, pop: false});
                            }} />

                            <div className="form-group">
                                <label for="">Bank name</label>
                                <input type="bankName" name='bankName' onChange={this.handleInputChange} className="form-control" placeholder="Bank name" />
                            </div>
                            
                            <div className="form-group">
                                <label for="">Account name</label>
                                <input type="text" name='accountName' onChange={this.handleInputChange} className="form-control" placeholder="Account name" />
                            </div>

                            <div className="form-group">
                                <label for="">Account number</label>
                                <input type="number" name='accountNumber' onChange={this.handleInputChange} className="form-control" placeholder="Account number" />
                            </div>

                            <div className="form-group">    
                                <div className='col-lg-12'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label for="">Sort Code</label>
                                            <input type="text" name='sortCode' onChange={this.handleInputChange} className="form-control" placeholder="Sort Code" />
                                        </div>
                                        <div className='col-md-6'>
                                            <label for="">IBAN (Optional)</label>
                                            <input type="text" name='iban' onChange={this.handleInputChange} className="form-control" placeholder="IBAN" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label for="">Whose account is it</label>
                                <select onChange={this.handleInputChange} name='accontType' className="form-control">
                                    <option>Parent</option>
                                    <option>Teen (You)</option>
                                </select>
                            </div>

                            {emsg.length ? <span className='err-msg for-step-0'>{emsg}</span> : null}
                            <button className='payt_btn' style={{opacity: loading ? 0.5 : 1}} onClick={() => {
                                if(!loading) {
                                    this.addBdet();
                                }
                            }}>Save</button>
                        </div> : pstep == 3 && active_bdet ? <div className="con-pop ps-3">
                            <strong>Withdrawal details</strong>
                            <p>Available balance</p>
                            <b>£{balance}</b>
                            <img src={mod_cancel} className='mod_cancel' onClick={() => {
                                this.setState({pstep: 0, pop: false});
                            }} />

                            {/* <div className="form-group">
                                <label for="">Amount</label>
                                <input type="number" name='amount' onChange={this.handleInputChange} className="form-control" placeholder="Amount" />
                            </div> */}

                            <p style={{marginTop: "1em"}}>Your Bank Details</p>
                            <div className="form-group">
                                <label for="">Bank name</label>
                                <input type="text" className="form-control _ro" placeholder="Bank name" value={active_bdet.bankName} />
                            </div>

                            <div className="form-group">
                                <label for="">Account number</label>
                                <input type="number" className="form-control _ro" placeholder="Account number" value={active_bdet.accountNumber} />
                            </div>

                            <div className="form-group">
                                <label for="">Account name</label>
                                <input type="text" className="form-control _ro" placeholder="Account name" value={active_bdet.accountName} />
                            </div>
                            {emsg.length ? <span className='err-msg for-step-0'>{emsg}</span> : null}
                            <button className='payt_btn' style={{opacity: balance > 0 ? 1 : 0.5}} onClick={() => {
                                this.withdraw();
                            }}>Withdraw</button>
                        </div> : null}

                    </div>
                : null}
            </Container>
        );
    }
}

export default Earnings;