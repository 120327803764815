import React, { Component } from 'react';
import $ from "jquery";
import '../../App.scss';
import '../../Mobile.scss';
import Api from '../../Api.js';
import g from '../../Assets/Images/g.svg';
import logo from '../../Assets/Images/logo.svg';
import si1 from '../../Assets/Images/si-1.png';
import si2 from '../../Assets/Images/si-2.png';
import si3 from '../../Assets/Images/si-3.png';
import si4 from '../../Assets/Images/si-4.png';
import si5 from '../../Assets/Images/si-5.png';
import si6 from '../../Assets/Images/si-6.png';
import si7 from '../../Assets/Images/si-7.png';

class SignUp extends Component {

    constructor(props) {
        super(props);
    
        let guardianMode = this.props.history.location.pathname.replace("/", "").toLowerCase() == "guardian";

        this.state = {
            step: guardianMode ? 1 : 0,
            emsg: "",
            user: null,
            eye: false,
            userId: null,
            agree: false,
            loading: false,
            showTC: false,

            umail: "",

            email: "",
            password: "",
            fullName: "",
            dateOfBirth: "",
            relationship: "",
            houseAddress: "",
            parentGuardian: "",
            parentGuardianEmail: "",

            guardianMode: guardianMode
        };
    }
  
    async componentDidMount () {
      let user = window.localStorage.getItem("seller");
          user = JSON.parse(window.localStorage.getItem("seller"));
          if(user) {
            this.setState({user, userId: user.userId, umail: user.email});
          }
    }

    submit_one = async () => {
        let { email, password, fullName, dateOfBirth } = this.state;
        this.setState({loading: true});
        await Api.post({email, password, fullName, dateOfBirth}, "api/Auth/register")
        .then((resp) => {
            // console.log(resp.data, "-------");
            // window.localStorage.setItem("seller", JSON.stringify(resp.data.data));
            window.location.href = "/sign-in"; // Set route permissions
            this.setState({loading: false});
        }).catch((obj) => {
            if(!obj.response) { this.setState({emsg: "Network error", loading: false}); return false; }
            let errors = obj.response.data.errors;
            if(errors) {
                let efld = Object.keys(errors)[0];
                let emsg = errors[efld][0];
                this.setState({emsg, loading: false});
            } else {
                this.setState({emsg: obj.response.data?.message, loading: false});
            }
        });
    }

    submit_two = async () => {
        let { relationship, houseAddress, parentGuardian, parentGuardianEmail, user, userId, umail } = this.state;
        let eRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let ready = parentGuardian.length
                  && relationship.length
                  && houseAddress.length;

        if(ready) {
            if(parentGuardianEmail.length && parentGuardianEmail.match(eRegx)) {
                if(parentGuardianEmail == umail) {
                    this.setState({emsg: "Your Guardian email address can't be the same as yours", loading: false});
                } else {
                    this.setState({loading: true});
                    await Api.post({userId, relationship, houseAddress, parentGuardian, parentGuardianEmail}, "api/Auth/onboarding")
                    .then((resp) => {
                        // console.log(resp.data.data, "-------");
                        // window.localStorage.setItem("seller", JSON.stringify(resp.data.data));
                        window.location.href = "/home"; // Set route permissions
                        this.setState({loading: false});
                    }).catch((obj) => {
                        if(!obj.response) { this.setState({emsg: "Network error", loading: false}); return false; }
                        let errors = obj.response.data.errors;
                        if(errors) {
                            let efld = Object.keys(errors)[0];
                            let emsg = errors[efld][0];
                            this.setState({emsg, loading: false});
                        } else {
                            this.setState({emsg: obj.response.data?.message, loading: false});
                        }
                    });
                }
            } else {
                this.setState({emsg: "Invalid Parent Guardian email address", loading: false});
            }
        } else {
            this.setState({emsg: "Please fill all fields", loading: false});
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value.trim()
        });
    };

    render () {
        let { step, agree, eye, emsg, fullName, dateOfBirth, email, password, showTC, loading } = this.state;
        return (
            <div className="App">
                <header className="App-header container">
                    <a className='logo-hl' href="https://brandinit.com" target="_blank">
                    <img src={logo} className='App-logo' />
                    </a>
                    <div></div>
                </header>
                <main className={'App-body container'+(step ? ' flex-col' : '')}>
                    {step ? <>
                        <h1 className='mainh bb b2'>Final Steps!</h1>
                        <p className='sign-p'>We need your Parent/Guardian information to verify who you are! We will send them an email, once they confirm your identity you can start selling!</p>
                    </> : null}
                    <div className={'rf-sect step-'+step}>
                        <form action='/sign-in' className={'for-step-'+step} onSubmit="return false;">
                            <div className="form-group for-step-1">
                                <label for="">Parent/Guardian Full name</label>
                                <input type="text" name='parentGuardian' onChange={this.handleInputChange} className="form-control" placeholder="Enter parent/guardian full name" />
                            </div>
                            <div className="form-group for-step-1">
                                <label for="">Relationship</label>
                                <input type="text" name='relationship' onChange={this.handleInputChange} className="form-control" placeholder="Relationship" />
                            </div>
                            <div className="form-group for-step-1">
                                <label for="">Parent/Guardian Email address</label>
                                <input type="email" name='parentGuardianEmail' onChange={this.handleInputChange} className="form-control" placeholder="guardianemail@email.com" />
                            </div>
                            <div className="form-group for-step-1">
                                <label for="">House address</label>
                                <input type="text" name='houseAddress' onChange={this.handleInputChange} className="form-control" placeholder="Enter your address" />
                            </div>
                            <label className="form-group form-check for-step-1" for="ia-check-1">
                                <input type="checkbox" className="form-check-input" id="ia-check-1" onChange={() => {
                                    this.setState({agree: $("#ia-check-1").is(":checked")});
                                }} />
                                <span className="form-check-label label">I hereby agree to the Parent/Guardian Policy of BrandinIt.</span>
                            </label>
                            <a className='pg for-step-1' href='javascript:void(0)' onClick={() => {
                                this.setState({showTC: true});
                            }}>Parent/Guardian Policy</a>

                            {emsg.length ? <span className='err-msg for-step-1'>{emsg}</span> : null}
                            
                            <button type="button" className="btn btn-primary submit-btn for-step-1" onClick={() => {
                                if(agree) { this.submit_two(); }
                            }} style={{opacity: agree && !loading ? 1 : 0.6}}>Continue</button>

                            <h1 className='mainh for-step-0'>Sign up to<br />create your brand</h1>
                            <div className="form-group for-step-0">
                                <label for="">Full name</label>
                                <input type="text" name='fullName' onChange={this.handleInputChange} className="form-control" id="" placeholder="Enter your full name" />
                            </div>
                            <div className="form-group for-step-0">
                                <label for="">Your Date of Birth</label>
                                <input type="date" name='dateOfBirth' onChange={this.handleInputChange} className="form-control" placeholder="DD/MM/YY" />
                            </div>
                            <div className="form-group for-step-0">
                                <label for="">Email address</label>
                                <input type="email" name='email' onChange={this.handleInputChange} className="form-control" id="" placeholder="Enter email" />
                            </div>
                            <div className="form-group for-step-0" style={{position: "relative"}}>
                                <label for="">Password</label>
                                <input type={eye ? "text" : "password"} name='password' onChange={this.handleInputChange} className="form-control" id="" placeholder="Password" />
                                <span className='eye-tog' onClick={() => { this.setState({eye: !eye}); }}>
                                {eye ? <i class="ri-eye-line"></i> : <i class="ri-eye-off-line"></i>}
                                </span>
                            </div>
                            <label className="form-group form-check for-step-0" for="ia-check-0">
                                <input type="checkbox" className="form-check-input" id="ia-check-0" onChange={() => {
                                    this.setState({agree: $("#ia-check-0").is(":checked")});
                                }} />
                                <span className="form-check-label label">I hereby agree to the Rules and Regulation of BrandinIt by signing up.</span>
                            </label>
                            <a className='pg for-step-0' href='javascript:void(0)' onClick={() => {
                                this.setState({showTC: true});
                            }}>Rules and Regulation</a>

                            {emsg.length ? <span className='err-msg for-step-0'>{emsg}</span> : null}
                            
                            <button type="button" className="btn btn-primary submit-btn for-step-0" style={{opacity: agree && !loading ? 1 : 0.6}} onClick={() => {
                                var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                                var passRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
                                if(email.length && email.match(emailRegex)) {
                                    if(fullName.length > 3 && fullName.indexOf(' ') > 0) {
                                        if(dateOfBirth.length > 3) {
                                            if(password.length >= 8) { // && password.match(passRegex)
                                                if(agree) {
                                                    this.submit_one();
                                                } else {
                                                    this.setState({emsg: "Pending sign up agreement"});
                                                }
                                            } else {
                                                this.setState({emsg: "Password should be minimum eight characters"});
                                            }
                                        } else {
                                            this.setState({emsg: "Please state your date of birth"});
                                        }
                                    } else {
                                        this.setState({emsg: "Please state your full name"});
                                    }
                                } else {
                                    this.setState({emsg: "Invalid email address"});
                                }
                            }}>Create account</button>
                            {/* <button type="button" className="btn btn-default submit-btn for-step-0"><img src={g} alt='google' className='gvg' /><span>Sign up with Google</span></button> */}
                            <a className='pg cn for-step-0' href='/sign-in'>I already have an account</a>
                        </form>
                    </div>

                    {step ? null :
                    <div className='md-sect'>
                        <div className='md-sect-cover'>
                            <div className='md-rw'>
                                <img src={si1} alt='' />
                                <img src={si2} alt='' />
                            </div>
                            <div className='md-rw'>
                                <img src={si3} alt='' />
                                <img src={si4} alt='' />
                                <img src={si5} alt='' />
                            </div>
                            <div className='md-rw'>
                                <img src={si6} alt='' />
                                <img src={si7} alt='' />
                            </div>
                        </div>
                    </div>}
                </main>

                {showTC ?
                    <div className='modal-bg'>
                        {step ? 
                        <div className='modal-pp'>
                            <h2>Terms and Conditions of Parents/Guardians</h2>
                            <div className='flex-contnt'>
                                <ul className='modal-ul'>
                                    <li>
                                        <b>Introduction</b>
                                        <span>These Terms and Conditions govern your use of Brandin'It as a parent or guardian ("You" or "Your") of a minor child who wishes to participate in the Brandin'It platform ("Your Child" or "Participant"). By using Brandin'It, you agree to comply with these Terms and Conditions.</span>
                                    </li>
                                    <li>
                                        <b>Eligibility</b>
                                        <span>Your Child or Participant is aged between 9-19 years old, and you have the legal authority to enter these Terms and Conditions on behalf of Your Child or Participant. By allowing Your Child or Participant to use Brandin'It, you represent and warrant that You have such authority.</span>
                                    </li>
                                    <li>
                                        <b>Parental Responsibility</b>
                                        <span>You are solely responsible for supervising Your Child's or Participant use of Brandin'It and ensuring that it complies with these Terms and Conditions. You agree to monitor Your Child's activities on the platform and acknowledge that Brandin'It is not responsible for any misuse or inappropriate behaviour by Your Child or Participant.</span>
                                    </li>
                                    <li>
                                        <b>Use of Brandin'It</b>
                                        <span>Your Child or Participant may use Brandin'It to participate in educational activities, create and sell custom products, and interact with other users. You acknowledge and agree that Your Child's participation in these activities is at Your own risk, and You release Brandin'It from any liability arising from Your Child's use of the platform.</span>
                                    </li>
                                    <li>
                                        <b>Monitoring and Supervision</b>
                                        <span>You agree to monitor and supervise Your Child's or Participant's activities on Brandin'It to ensure compliance with these Terms and Conditions and any applicable laws or regulations. If You become aware of any violation or inappropriate behaviour, you agree to notify Brandin'It immediately.</span>
                                    </li>
                                    <li>
                                        <b>Termination</b>
                                        <span>Brandin'It reserves the right to terminate Your Child's or Participant account or restrict access to the platform if it believes that Your Child or Participant has violated these Terms and Conditions or engaged in inappropriate behaviour. You acknowledge that Brandin'It may take such actions without prior notice.</span>
                                    </li>
                                    <li>
                                        <b>Modifications</b>
                                        <span>Brandin'It may modify these Terms and Conditions at any time by posting the revised version on the platform. Your continued use of Brandin'It after any such changes constitute Your acceptance of the modified Terms and Conditions.</span>
                                    </li>
                                    <li>
                                        <b>Contact Information</b>
                                        <span>If You have any questions or concerns about these Terms and Conditions, you may contact Brandin'It at <a href='mailto:info@brandinit.co.uk'>info@brandinit.co.uk</a></span>
                                    </li>
                                </ul>
                                <p>By using Brandin'It, you acknowledge that You have read, understood, and agree to be bound by these Terms and Conditions. If You do not agree to these Terms and Conditions, you should not allow Your Child or Participant to use Brandin'It.</p>
                            </div>
                            <center>
                                <button type="button" className="btn btn-success submit-btn for-step-1" style={{marginTop: "1.5em", width: "200px"}} onClick={() => {
                                    $(".form-check-input").attr("checked", true);
                                    this.setState({showTC: false, agree: true});
                                }}>Agree</button>
                            </center>
                        </div> :
                        <div className='modal-pp'>
                            <h2>Terms and Conditions for Teenage Users:</h2>
                            <p>By accessing and using Brandin'it, you agree to abide by the following terms and conditions:</p><br />
                            <div className='flex-contnt'>
                                <ul className='modal-ul'>
                                    <li>
                                        <b>Respectful Conduct:</b>
                                        <span>- Users must engage in respectful behaviour when interacting on the site.</span>
                                        <span>- Harassment, bullying, or any form of disrespectful conduct towards other users is strictly prohibited and can result in you loosing your Brandin it accounts and loss of your available funds</span>
                                    </li>
                                    <li>
                                        <b>Originality and Branding:</b>
                                        <span>- Users pledge not to copy or imitate existing brands and branding, respecting intellectual property rights.</span>
                                        <span>- Any content shared on the website should be original and in compliance with copyright laws and brandin it rules. Any legal issues will be directed at the parent/guardian who verified the account.</span>
                                    </li>
                                    <li>
                                        <b>Treat Others with Respect:</b>
                                        <span>- Teenagers using the site commit to treating all users with kindness and respect, fostering a positive online community.</span>
                                        <span>- Discrimination, hate speech, or any form of harmful behaviour is not tolerated.</span>
                                    </li>
                                    <li>
                                        <b>Purpose of Website Use:</b>
                                        <span>- The website is to be used solely for the purpose of promoting/creating brands and earning income (unless Brandin chooses to expand)</span>
                                        <span>- Users agree not to engage in any activities that deviate from the intended use of the platform.</span>
                                    </li>
                                    <li>
                                        <b>Report Suspicious Behaviour:</b>
                                        <span>- Users are encouraged to promptly report any suspicious or inappropriate behaviour observed on the site.</span>
                                        <span>- Reporting can be done through designated channels provided on the platform.</span>
                                    </li>
                                    <li>
                                        <b>Parental Consent:</b>
                                        <span>- Users affirm that they have obtained parental consent before accessing the site if required by applicable laws view the parental verification.</span>
                                    </li>
                                    <li>
                                        <b>Compliance with Laws:</b>
                                        <span>- Users must comply with all local, national, and international laws and regulations governing online conduct.</span>
                                    </li>
                                    <li>
                                        <b>Account Responsibility:</b>
                                        <span>- Teenagers are responsible for the activity conducted through their accounts, and they agree not to share login credentials.</span>
                                    </li>
                                    <li>
                                        <b>Modification of Terms:</b>
                                        <span>-Brandin it reserves the right to modify these terms and conditions at any time, and users will be notified of such changes.</span>
                                    </li>
                                    <li>
                                        <b>Termination of Access:</b>
                                        <span>Brandin it reserves the right to terminate access for any user violating these terms and conditions without prior notice.</span>
                                    </li>
                                </ul>
                                <p>By using Brandin it LTD, you acknowledge and agree to be bound by these terms and conditions. Failure to comply may result in the termination of your account. If you have any questions or concerns, please contact us at <a href='mailto:info@brandinit.co.uk'>info@brandinit.co.uk</a>.</p>
                            </div>
                            <center>
                                <button type="button" className="btn btn-success submit-btn for-step-1" style={{marginTop: "1.5em", width: "200px"}} onClick={() => {
                                    $(".form-check-input").attr("checked", true);
                                    this.setState({showTC: false, agree: true});
                                }}>Agree</button>
                            </center>
                        </div>}
                    </div>
                : null}
            </div>
        );
    }

}

export default SignUp;