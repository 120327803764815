import '../../App.scss';
import $ from "jquery";
import Api from '../../Api.js';
import React, { Component } from 'react';
import logo from '../../Assets/Images/logo.svg';
import nike from '../../Assets/brands/nike.png';
import lv from '../../Assets/brands/lv.png';
import gucci from '../../Assets/brands/gucci.webp';
import dior from '../../Assets/brands/dior.png';
import adidas from '../../Assets/brands/adidas.png';
import rolex from '../../Assets/brands/rolex.png';
import zara from '../../Assets/brands/zara.webp';

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;
  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array;
}

class Create extends Component {

  constructor(props) {
      super(props);
      this.state = {
        emsg: "",
        user: null,
        loading: false,
        brand_name: "",
        brandDescription: "",
        suggestion: true,
        suf: ["LTD", "Collections", "Fashion", "Beauty", "Wears", "Luxury", "Luxe", "Studio", "Attire", "Boutique", "Tailor", "Style", "Vogue", "Couture", "Apparel", "Styles", "Classics", "Attire"]
      };
  }
  
  async componentDidMount () {
    let user = window.localStorage.getItem("seller");
        user = JSON.parse(window.localStorage.getItem("seller"));
    this.setState({suf: shuffle(this.state.suf), user});
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
        [name]: value.trim()
    });
  };
  
  createBrand = async () => {
    let { brand_name, brandDescription, user } = this.state;
    if(user) {
      if(brand_name.length) {
        if(brand_name.split(" ").length > 10) {
          this.setState({emsg: "Brand name cannot be more than 10 words", loading: false})
        } else {
          this.setState({loading: true});
          await Api.post({ brandName: brand_name, brandDescription }, "api/Brand/createbrand", {"Authorization": 'Bearer '+user?.token})
          .then((resp) => {
            window.location.href = '/upload_logo';
          }).catch((obj) => {
            if(!obj.response) { this.setState({emsg: "Network error", loading: false}); return false; }
            this.setState({emsg: obj.response.data.message, loading: false});
          });
        } 
      } else {
        this.setState({emsg: "Brand name cannot be empty", loading: false})
      }
    } else {
      window.location.href = '/sign-in';
    }
  }

  render () {
    let { emsg, brand_name, suf, suggestion, loading } = this.state;
    return (
      <div className="App">
        <header className="App-header wel">
            <a className='logo-hl' href="https://brandinit.com" target="_blank">
              <img src={logo} className='App-logo' />
            </a>
          <div></div>
        </header>
        <main className='App-body container main-body cr'>
          <div className='pp-body cn'>
              <h1 className='mainh' style={{textAlign: "center"}}>Create your brand name</h1>
              <p style={{marginTop: "20px", marginBottom: "5px", textAlign: "center"}}>This is what people will call your brand, make it unique, make it You.</p>
              {/* <div className='clogos'>
                <img src={lv} alt='' className='' />
                <img src={nike} alt='' className='' />
                <img src={gucci} alt='' className='' />
                <img src={adidas} alt='' className='' />
                <img src={dior} alt='' className='' />
                <img src={rolex} alt='' className='' />
                <img src={zara} alt='' className='' />
              </div> */}
              <input type='text' placeholder='Enter your brand name' id="brand_name" className='ebn' onKeyUp={(e) => {
                this.setState({brand_name: e.target.value.trim(), suggestion: true, emsg: ""}); // , suf: shuffle(suf)
              }} />
              <textarea type='text' placeholder='Please describe your brand (Optional)' id="brandDescription" className='ebn tx' style={{paddingTop: "10px", height: "100px"}} onKeyUp={(e) => {
                this.setState({brandDescription: e.target.value.trim(), suggestion: false, emsg: ""}); // , suf: shuffle(suf)
              }}></textarea>

              {brand_name.length && suggestion ?
                <>
                  <span style={{marginTop: "20px"}}>Some suggestions for you</span>
                  <div className='ssfy'>
                    <button onClick={() => {
                      let bname = brand_name+" "+suf[0];
                      $("#brand_name").val(bname);
                      this.setState({brand_name: bname, suggestion: false});
                    }}>{brand_name} {suf[0]}</button>
                    <button onClick={() => {
                      let bname = brand_name+" "+suf[1];
                      $("#brand_name").val(bname);
                      this.setState({brand_name: bname, suggestion: false});
                    }}>{brand_name} {suf[1]}</button>
                    <button onClick={() => {
                      let bname = brand_name+" "+suf[2];
                      $("#brand_name").val(bname);
                      this.setState({brand_name: bname, suggestion: false});
                    }}>{brand_name} {suf[2]}</button>
                    <button onClick={() => {
                      let bname = brand_name+" "+suf[3];
                      $("#brand_name").val(bname);
                      this.setState({brand_name: bname, suggestion: false});
                    }}>{brand_name} {suf[3]}</button>
                    <button onClick={() => {
                      let bname = brand_name+" "+suf[4];
                      $("#brand_name").val(bname);
                      this.setState({brand_name: bname, suggestion: false});
                    }}>{brand_name} {suf[4]}</button>
                  </div>
                </>
              : null}
              
              {emsg.length ? <span className='err-msg'>{emsg}</span> : null}

              <button className='btn-next' onClick={() => {
                this.createBrand();
              }} style={{marginTop: "20px", opacity: brand_name.length && !loading ? 1 : 0.5}}>Next</button>
              {/* <a className='pg cn' href='/help' style={{marginTop: "20px"}}>{brand_name.length ? "Save and Continue Later" : "Need help?"}</a> */}
              <a className='pg cn' href='https://brandinit.co.uk/contact-us/' target="_blank" style={{marginTop: "20px"}}>Need help?</a>
          </div>
        </main>
      </div>
    );
  }
}

export default Create;