import $ from 'jquery';
import React, { Component } from 'react';
import Api from '../../Api.js';
import '../../App.scss';
import logo from '../../Assets/Images/logo.svg';

class UploadLogo extends Component {

  constructor(props) {
    super(props);

    this.state = {
      emsg: '',
      user: null,
      image: null,
      loading: false,
      imageData: null
    };
  }
  
  async componentDidMount () {
    let user = window.localStorage.getItem("seller");
        user = JSON.parse(window.localStorage.getItem("seller"));
    this.setState({ user });

    let dis = this;
    let dropArea = document.getElementById("dropArea");
    let inputFile = document.getElementById("logoup");
    dropArea.addEventListener("dragover", function (e) {
      e.preventDefault();
    });
    dropArea.addEventListener("drop", function (e) {
      e.preventDefault();
      inputFile.files = e.dataTransfer.files;
      dis.setState({image: URL.createObjectURL(inputFile.files[0]), imageData: inputFile.files[0]});
    });
  }
  
  createBrandLogo = async () => {
    let { imageData, image, user, loading } = this.state;
    if(user) {
      if(image && imageData) {
        var c = document.createElement('canvas');
        var img = document.getElementById('ulogo-preview');
        c.height = img.naturalHeight;
        c.width = img.naturalWidth;
        var ctx = c.getContext('2d');
        ctx.drawImage(img, 0, 0, c.width, c.height);
        var base64String = c.toDataURL().replace('data:', '').replace(/^.+,/, ''); // 'image/png'
        this.setState({emsg: '', loading: true});
        await Api.post({
          "filename": imageData.name,
          "mimeType": imageData.type,
          "base64Content": base64String
          }, "api/Brand/createbrandlogo", {"Authorization": 'Bearer '+user?.token
        }).then((resp) => {
          window.location.href = '/createProduct';
          this.setState({loading: false});
        }).catch((obj) => {
          if(!obj.response) { this.setState({emsg: "Network error", loading: false}); return false; }
          this.setState({emsg: obj.response.data.message, loading: false});
        });
      }
    }
  }

  render () {
    let { image, emsg, loading } = this.state;
    return (
      <div className="App">
        <header className="App-header wel">
            <a className='logo-hl' href="https://brandinit.com" target="_blank">
              <img src={logo} className='App-logo' />
            </a>
          <div></div>
        </header>
        <main className='App-body container main-body cr'>
          <div className='pp-body cn' style={{paddingTop: "50px"}}>
              <h1 className='mainh'>Upload your logo</h1>

              <form style={{display: "none"}} method="post" enctype="multipart/form-data">
                <input type="file" id="logoup" accept="image/png, image/jpeg" onChange={(e) => {
                  this.setState({image: URL.createObjectURL($(e.target)[0].files[0]), imageData: $(e.target)[0].files[0]});
                }} />
              </form>
              
              <label for="logoup" id="dropArea" className={'gsv-cover ulogo'+(image ? ' im' : '')}>
                  {image ? <img src={image} alt='' id="ulogo-preview" onClick={() => {
                    $("#logoup").click();
                  }} /> : null}
                  <button className='btn-next' onClick={() => {
                    if(image) {
                      this.createBrandLogo();
                    } else {
                      $("#logoup").click();
                    }
                  }} style={{opacity: loading ? 0.5 : 1}}>Upload{image ? "" : " logo"}</button>
                  {image ? null : <>
                      <b>or drag and drop image</b>
                      <span>You can also paste or Ctrl/Cmd + V</span>
                    </>
                  }
              </label>
              
              {emsg.length ? <span className='err-msg alt for-step-0' style={{marginTop: "20px"}}>{emsg}</span> : null}
              
              <a className='pg cn' href='https://brandinit.co.uk/contact-us/' target="_blank" style={{marginTop: "20px"}}>Need help?</a>
              {/* <a className='btn-skip' href='/createProduct' style={{marginTop: "1em"}}>{image && false ? "Save and Continue Later" : "Skip"}</a> */}
          </div>
        </main>
      </div>
    );
  }
}

export default UploadLogo;