import React, { Component } from 'react';
import $ from "jquery";
import '../../App.scss';
import '../../Mobile.scss';
import Api from '../../Api.js';
import g from '../../Assets/Images/g.svg';
import logo from '../../Assets/Images/logo.svg';
import si1 from '../../Assets/Images/lo-1.png';
import si2 from '../../Assets/Images/lo-2.png';
import si3 from '../../Assets/Images/lo-3.png';
import si4 from '../../Assets/Images/lo-4.png';
import si5 from '../../Assets/Images/lo-5.png';
import si6 from '../../Assets/Images/lo-6.png';
import si7 from '../../Assets/Images/lo-7.png';
import sp7 from '../../Assets/Images/sp7.jpg';
import sp8 from '../../Assets/Images/sp8.jpg';
import ds6 from '../../Assets/Images/DS6.jpg';
import ds8 from '../../Assets/Images/DS7.png';
import ds9 from '../../Assets/Images/DS9.jpg';

class SignIn extends Component {

  constructor(props) {
    super(props);

    this.state = {
      eye: false,
      emsg: "",
      email: "",
      password: "",
      loading: false
    };
  }
  
  async componentDidMount () {
    window.localStorage.removeItem("seller");
  }

  submit = async () => {
      let { email, password } = this.state;
      let eRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(email.length && email.match(eRegx)) {
        if(password.length >= 8) {
          this.setState({loading: true});
          await Api.post({email, password}, "api/Auth/login")
          .then((resp) => {
            let udata = resp.data.data;
            let _goto = "welcome";
            window.localStorage.setItem("seller", JSON.stringify(udata));
            if(udata.brandCount > 0) {
              if(udata.productCount > 0) {
                if(udata.hasFilledGuardian) {
                  _goto = "/home";
                } else {
                  _goto = "/guardian";
                }
              } else {
                _goto = "/customProduct";
              }
            } else {
              _goto = "/welcome";
            }
            window.location.href = _goto; // Set route permissions
          }).catch((obj) => {
            if(obj?.response) {
              this.setState({emsg: obj.response.data.message || "Something went wrong", loading: false});
            } else {
              this.setState({emsg: "An error occurred", loading: false});
            }
          });
        } else {
          this.setState({emsg: "Password should be minimum eight characters", loading: false});
        }
      } else {
        this.setState({emsg: "Invalid email address", loading: false});
      }
  }

  handleInputChange = (e) => {
      const { name, value } = e.target;
      this.setState({
          [name]: value.trim()
      });
  };

  render () {
    let { emsg, eye, loading } = this.state;
      return (
        <div className="App">
          <header className="App-header container">
            <a className='logo-hl' href="https://brandinit.com" target="_blank">
              <img src={logo} className='App-logo' />
            </a>
            <div></div>
          </header>
          <main className='App-body container'>
            <div className='rf-sect'>
                <form action='/sign-in' onSubmit="return false;">
                    <h1 className='mainh'>Sign in to Brandin'it</h1>
                    <div className="form-group">
                        <label for="">Email address <small style={{color: "rgb(130, 130, 130)"}}>(Personal or school)</small></label>
                        <input type="email" name='email' onChange={this.handleInputChange} className="form-control" placeholder="Enter email" />
                    </div>
                    <div className="form-group" style={{position: "relative"}}>
                        <label for="">Password</label>
                        <input type={eye ? "text" : "password"} name='password' onChange={this.handleInputChange} className="form-control" placeholder="Password" />
                        <span className='eye-tog' onClick={() => { this.setState({eye: !eye}); }}>
                          {eye ? <i class="ri-eye-line"></i> : <i class="ri-eye-off-line"></i>}
                        </span>
                    </div>
                    <a className='pg ri' href='/reset-password'>Forgot password</a>
                    {emsg.length ? <span className='err-msg for-step-0'>{emsg}</span> : null}
                    <button type="button" onClick={this.submit} className="btn btn-primary submit-btn" style={{opacity: loading ? 0.5 : 1}}>Sign in</button>
                    {/* <button type="button" className="btn btn-default submit-btn"><img src={g} alt='google' className='gvg' /><span>Continue with Google</span></button> */}
                    <a className='pg cn' href='/sign-up'>I don't have an account</a>
                </form>
            </div>
    
            <div className='md-sect'>
                <div className='md-sect-cover'>
                    <div className='md-rw'>
                        <img src={si1} alt='' />
                        <img src={si2} alt='' />
                    </div>
                    <div className='md-rw'>
                        <img src={si3} alt='' />
                        <img src={si4} alt='' />
                        <img src={sp7} alt='' style={{borderRadius: "6px"}} />
                    </div>
                    <div className='md-rw'>
                        <img src={si6} alt='' />
                        <img src={sp8} alt='' style={{borderRadius: "6px"}} />
                    </div>
                </div>
            </div>
          </main>
        </div>
      );
  }

}

export default SignIn;