import '../../App.scss';
import React, { Component } from 'react';
import $ from "jquery";
import YouTube from 'react-youtube';
import logo from '../../Assets/Images/logo.svg';
import play from '../../Assets/Images/play.svg';
import check from '../../Assets/Images/check.svg';

class GStarted extends Component {

  constructor(props) {
    super(props);
    this.state = {
      y_w: 0,
      y_h: 0,
    };
  }
  
  async componentDidMount () {
    this.youtubeDim();
    $(window).resize(() => {
        this.youtubeDim();
    });
  }

  youtubeDim () {
    this.setState({
      y_w: $(".gsv.y").innerWidth(),
      y_h: $(".gsv.y").innerWidth() / 1.98
    });
  }

  render() {
    let { props } = this;
    let { y_w, y_h } = this.state;
    let url = window.location.search;
    let yids = ["F21BAUSCqU8", "G2Jyxt9zyp8", "-ejrB0e7p2k"];
    let trhs = [<>How Brandinit Works?</>, <>Creating Your Brand</>, <>Selling Your Product</>];
    let trns = [
      <>Welcome to Brandin’It – the place where your creativity becomes a business adventure.
      Today, we&#39;re gonna take you through all the steps to kickstart your journey as a Brandin’It
      entrepreneur. So, let&#39;s dive in!<br /><br />
      <b>Step 1:</b> Register on our site and create an account, we just need some details so we can
      host your brand on our site and say we know you when you’re famous!<br /><br />
      <b>Step 2:</b> Learn how it works here! We have created videos just like this for you to learn how to
      start your brandin it journey with some business hints and tips along the way. From pricing to
      creating your logo we will teach you how it’s done.<br /><br />
      <b>Step 3:</b> Time to get creative! Once you&#39;ve aced the business basics, it&#39;s design time; you
      create your logo or choose from our library of designs or upload your very own masterpiece.
      Your creativity knows no bounds here.<br /><br />
      <b>Step 4:</b> Let’s make it real! Pick a product from our list and get creative, we provide guildlines
      to make sure yours is completely unique. Then set your price; how much do you want to
      make? You decide!<br /><br />
      After these steps we just need to confirm your identity with a parent or guardian
      And that’s it you’re now officially a Brandin’It entrepreneur! You’re then part of the Brandin’It
      family, where creativity meets entrepreneurship. So go ahead, start your business journey,
      and let the world see the amazing creations you’re about to unleash.</>,
      <>Let&#39;s get into the world of defining your brand identity.<br /><br />
      Creating a brand is all about you – what colors, logos, or symbols would represent you and
      your personality? Just like other brands have their unique designs, your brand needs a
      memorable logo and colour theme. Why? So that when anyone sees it, they instantly know
      it&#39;s you!<br /><br />
      Check out the Nike swoosh or Nintendo. It comes straight to mind, right? Yours should do
      the same. It can be as simple or elaborate as you want just make it something you would be
      proud of. At Brandin it you can either unleash your inner artist and design your brand logo or
      choose from our library of amazing options. It&#39;s all about making your mark in style!
      Here are the questions you need to ask yourself:<br /><br />
      What brands do I like and how can I make my own version?<br /><br />
      What kind of customers do I want to reach?<br /><br />
      What do I want by Brand to feel like?<br /><br />
      Take time and reflect on this but don’t worry If it’s not perfect straight away. The best designs
      take time. For example do you know what brand this is?<br />
      <img src='https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_first_logo.png' alt='' /><br />
      Well, this logo is now this:<br />
      <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Apple_logo_grey.svg/647px-Apple_logo_grey.svg.png' alt='' /><br />
      - Remember there’s always space to grow!</>,
      <>Hey, let&#39;s unravel the mysteries of pricing and selling your awesome product
      The perfect price tag is really important, think about your potential clients what kinds of
      brands to they wear now? Are they expensive brands or more affordable? Then think about
      what makes your product stand out and how much it’s worth. Once you&#39;ve got that figured
      out, it&#39;s time to set a price that reflects the amazing qualities.<br /><br />
      Now, let&#39;s talk about the art of selling. Imagine you&#39;re convincing your friends why your
      sports team is the best – selling your product is a bit like that! Highlight the coolest features,
      explain how it solves a problem, and make it irresistible.<br /><br />
      Here’s an example, Hey my name is David, and I sell T-shirts with my own unique design
      that was inspired by my passion for skating, if you’re into skating you will love this T-shirt! It’s
      only £30 so it’s affordable and perfect for everyday wear! – Makes sense?<br /><br />
      So, how does pricing work at Brandin’It? It&#39;s simple! Let&#39;s break it down! First up, we have
      the cost price (which is how much it cost to make) then you add the profit amount, which is
      what you decide and keep.<br /><br />
      Picture this: You&#39;ve designed a T-shirt, and the cost price is £20. Now, let&#39;s say you want to
      make £10 in profit – Your pricing should be set at £30 and that’s it.<br /><br />
      Now, imagine that for every sale you make, that profit goes right into your Brandin it wallet
      for you to withdraw whenever you like. Whether it&#39;s for that new video game, concert tickets,
      or even saving up for something big – it&#39;s all up to you!<br /><br />
      Here’s a reminder of all the keywords you should know:<br />
      <b>Cost Price:</b> How much it cost us to make your product<br />
      <b>Profit:</b> How much you make from the product<br />
      <b>Shipping Cost:</b> How much it cost to deliver your product</>
    ]
    let _s = url.indexOf("?s=") > -1 ? url.replace("?s=", "") : "1";
        _s = Number(_s);
    let yid = yids[_s - 1];
    let trh = trhs[_s - 1];
    let trn = trns[_s - 1];
    let ds = props.summary || false;
    let nx = _s == 3 ? '/'+(ds ? 'home' : 'congrats') : '/'+(ds ? "training" : "getting-started")+'?s='+(_s + 1);
    return (
      <div className="App">
        {ds ? null : <header className="App-header container">
          <a className='logo-hl' href="https://brandinit.com" target="_blank">
            <img src={logo} className='App-logo' />
          </a>
          <div></div>
        </header>}
        <main className='App-body container main-body'>
          {ds ? null : <div className='g-steps'>
              <div className={'g-step _1'+(_s > 0 ? ' _active' : '')}>
                  <div className='g-st'></div>
                  <img src={check} alt='' className='g-st' />
                  <span>1</span>
              </div>
              <div className={'g-step _2'+(_s > 1 ? ' _active' : '')}>
                  <div className='g-st'></div>
                  <img src={check} alt='' className='g-st' />
                  <span>2</span>
              </div>
              <div className={'g-step _3'+(_s > 2 ? ' _active' : '')}>
                  <div className='g-st'></div>
                  <img src={check} alt='' className='g-st' />
                  <span>3</span>
              </div>
          </div>}
          <h1 className='mainh'>{trh}</h1>
          <p></p>
          {/* <p>At vero eos et accusamus et iusto odio dignissimos ducimus</p> */}
  
          {/* <video width="833" height="390" controls="false" className='gsv'>
              <source src="movie.mp4" type="video/mp4" />
              <source src="movie.ogg" type="video/ogg" />
          </video> */}
  
          <div className='gsv-cover'>
              <div className='gsv y'>
                {y_w > 0 ?
                  <YouTube videoId={yid} opts={{
                      height: y_h,
                      width: y_w,
                  }} /> : null}
                {/* <img src={play} alt='play' /> */}
              </div>
              <p className='gsp'>{trn}</p>
              <a className='btn-next' href={nx}>Next</a>
          </div>
          <a className='pg cn' href='https://brandinit.co.uk/contact-us/' target="_blank" style={{marginTop: "20px"}}>Need help?</a>
          {/* <a className='btn-skip' href='/congrats' style={{marginTop: "1em"}}>Skip</a> */}
        </main>
      </div>
    );
  }
}

export default GStarted;