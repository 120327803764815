import Container from './Container';
import { withRouter } from "react-router-dom";
import Api from '../../Api.js';
import $ from "jquery";
import React, { Component } from 'react';
import NewProduct from '../Onboarding/new_product';

class NewProductMain extends Component {

  constructor(props) {
    super(props);
    this.state = {
        token: "",
        user: null,
        cat_id: "",
        emsg: "",
        pcat: []
    };
  }
  
  async componentDidMount () {
    let user = window.localStorage.getItem("seller");
        user = JSON.parse(window.localStorage.getItem("seller"));
    let token = user?.token || "";
    this.setState({ token });
    window.addEventListener("message", ev => {
      if(ev.data == "saveProduct") {
        window.location.href = "/products";
      }
      if(ev.data == "loadedProduct") {
        $(".dash-can").addClass("pa");
      }
    });
  }

  render () {
    let { token } = this.state;
    return token.length ? (
      <Container page="newProduct">
        <div className="pagetitle">
          <div className="navrw">
              <button className='mob-hamburg for-mobile'>
                  <i className="bi bi-list toggle-sidebar-btn"></i>
              </button>
              <h1>New Product</h1>
          </div>
        </div>
        <iframe src={"https://brandinit-canvas-a7b3470770d9.herokuapp.com/?no_header=true&token="+token} className="dash-can" title=""></iframe>
      </Container>
      ) : null;
  }
}

export default NewProductMain;